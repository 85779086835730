import React from 'react';
import BreadcrumbSchema from '../../components/BreadcrumbSchema';
import { graphql } from 'gatsby';
import { getImage } from 'gatsby-plugin-image';
import { FaTasks } from 'react-icons/fa';
import ServiceTemplate from '../../components/service/ServiceTemplate';

const ManagementService = ({ data }) => {
  const seoData = {
    title: 'サイト運営代行｜奈良のホームページ制作なら｜インヴォルブ',
    description:
      '「サイトの更新が手間」「最新の情報を反映できない」などの課題を解決。日々の運営を代行し、安心してWebサイトを活用できるようにサポートします。',
    pageUrl: 'https://www.involve-in.jp/service/management',
    ogImage: 'https://www.involve-in.jp/images/common/ogp.png',
  };

  const breadcrumbList = [
    { name: 'ホーム', url: 'https://www.involve-in.jp/' },
    { name: 'サービス一覧', url: 'https://www.involve-in.jp/service/' },
    { name: 'サイト運営代行', url: seoData.pageUrl },
  ];

  const works = data.allContentfulCaseStudy.nodes;

  return (
    <>
      <BreadcrumbSchema breadcrumbList={breadcrumbList} />
      <ServiceTemplate
        title="サイト運営代行"
        subtitle="日々の運営をおまかせ"
        icon={FaTasks}
        description="「サイトの更新が手間」「最新の情報を反映できない」などの課題を解決。日々の運営を代行し、安心してWebサイトを活用できるようにサポートします。"
        seo={seoData}
        breadcrumbs={breadcrumbList}
        benefits={[
          {
            title: '手間を削減',
            description: '記事更新、画像変更、コンテンツ追加を代行。',
          },
          {
            title: '専門的な運用サポート',
            description: 'SEOを意識した更新作業も可能。',
          },
          {
            title: 'トラブル対応もおまかせ',
            description: '突然の不具合にも迅速対応。',
          },
        ]}
        flow={[
          {
            title: '運用方針のヒアリング',
            duration: '1日',
            description:
              'サイト運営の目的や更新頻度をヒアリングし、運用計画を立てる。',
          },
          {
            title: '定期更新の実施',
            duration: '継続',
            description:
              '記事の追加や修正、画像変更などをスケジュールに沿って実施。',
          },
          {
            title: '改善提案',
            duration: '随時',
            description: 'アクセス解析を基に、サイトの運営改善策を提案。',
          },
          {
            title: 'トラブル対応',
            duration: '随時',
            description: '予期せぬ不具合や障害が発生した場合、迅速に対応。',
          },
        ]}
        preparation={[
          {
            title: '運用方針の明確化',
            description: 'どのような更新を希望するのか、運用の方向性を決める。',
          },
          {
            title: '更新頻度の確認',
            description: 'どれくらいの頻度で更新を行うかを決定。',
          },
          {
            title: '必要な素材の準備',
            description: '画像や文章の提供が必要な場合、事前に用意。',
          },
          {
            title: '管理権限の共有',
            description:
              'CMSやサーバーのアクセス情報を確認し、適切な管理権限を設定。',
          },
        ]}
        works={works.map((work) => ({
          title: work.title,
          image:
            work.image && work.image.length > 0
              ? getImage(work.image[0].gatsbyImageData)
              : null,
        }))}
        price="¥15,000〜/月"
        cta="運営を任せる"
      />
    </>
  );
};

export const query = graphql`
  query {
    allContentfulCaseStudy(sort: { publishDate: DESC }) {
      nodes {
        title
        slug
        image {
          gatsbyImageData(
            layout: CONSTRAINED
            formats: [AUTO, WEBP]
            placeholder: BLURRED
          )
        }
      }
    }
  }
`;

export default ManagementService;
